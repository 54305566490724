import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { getStudies } from '../../helpers/api';
import { consolidateToMap } from '../../helpers/utils';

import { BaseStore } from '../BaseStore';
import { Study } from './Study';
import { StudyStore } from './StudyStore';
import { StudyFilesStore } from './StudyFilesStore';

// ==================================================================
// StudiesStore
// ==================================================================
const StudiesStore = BaseStore.named('StudiesStore')
  .props({
    studies: types.optional(types.map(Study), {}),
    studyStores: types.optional(types.map(StudyStore), {}),
    tickPeriod: 900 * 1000, // 15 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        let studies = await getStudies();
        self.runInAction(() => {
          consolidateToMap(self.studies, studies, (exiting, newItem) => {
            exiting.setStudy(newItem);
          });
        });
      },

      addStudy(rawStudy) {
        const id = rawStudy.id;
        const previous = self.studies.get(id);

        if (!previous) {
          self.studies.put(rawStudy);
        } else {
          previous.setStudy(rawStudy);
        }
        return self.studies.get(id);
      },

      getStudyStore: studyId => {
        let entry = self.studyStores.get(studyId);
        if (!entry) {
          // Lazily create the store
          self.studyStores.set(studyId, StudyStore.create({ studyId }));
          entry = self.studyStores.get(studyId);
        }

        return entry;
      },

      getStudyFilesStore: studyId => {
        return StudyFilesStore.create({ studyId: studyId });
      },

      cleanup: () => {
        self.studies.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.studies.size === 0;
    },

    get total() {
      return self.studies.size;
    },

    get list() {
      const result = [];
      self.studies.forEach(study => result.push(study));

      return _.reverse(_.sortBy(result, ['name']));
    },

    get numOfPages() {
      // TODO: implement pagination for when we have more data
      return 1;
    },
  }));

function registerModels(globals) {
  globals.studiesStore = StudiesStore.create({}, globals);
}

export { StudiesStore, registerModels };
