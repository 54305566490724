import _ from 'lodash';
import React from 'react';
import { makeObservable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import {
  Header,
  Table,
  Button,
  Dimmer,
  Loader,
  Segment,
  Pagination,
  Label,
  Checkbox,
} from 'semantic-ui-react';

import { formatBytes, swallowError, niceNumber } from '../../helpers/utils';
import { isStoreError, isStoreLoading, isStoreReloading } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';

class FilesTable extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      loading: computed,
      results: computed,
      // parentPrefix: computed,
      handlePaginationChange: action,
      handlePrefix: action,
      // handleTagChange: action,
      // handleSearchSubmit: action,
    });

    this.filesStore = this.props.filesStore;
    this.stores = [this.filesStore];
  }

  componentDidMount() {
    this.props.filesStore.load({ page: 1, reload: true });
  }

  get loading() {
    return isStoreLoading(this.stores) || isStoreReloading(this.stores);
  }

  get results() {
    return this.filesStore.visibleRecords || [];
  }

  /* get parentPrefix() {
    return this.filesStore.parentPrefix;
  } */

  get selectedPrefix() {
    return this.filesStore.selectedPrefix;
  }

  getFilesStore() {
    return this.filesStore;
  }

  handlePaginationChange = (e, { activePage }) => {
    if (this.loading) return; // we ignore page selection when the file store is loading/reloading
    const runId = this.filesStore.runId;
    const selectedPrefix = this.filesStore.selectedPrefix;
    swallowError(
      this.filesStore.load({
        page: activePage,
        reload: false, // don't reload
        runId,
        selectedPrefix,
      }),
    );
  };

  handlePrefix(prefix) {
    if (this.loading) return; // we ignore page selection when the file store is loading/reloading

    const runId = this.filesStore.runId;
    swallowError(
      this.filesStore.load({
        page: 1,
        reload: true,
        runId,
        selectedPrefix: prefix,
      }),
    );
  }

  render() {
    const store = this.getFilesStore();
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else {
      content = this.renderTable();
    }
    return (
      <>
        <Header as="h2" className="ml1" floated="left">
          Files
        </Header>
        {content}
      </>
    );
  }

  renderPagination() {
    const currentPage = this.filesStore.currentPage;
    const totalPages = this.filesStore.totalPages;
    if (totalPages < 2) return <div className="mb2" />;

    return (
      <Pagination
        activePage={currentPage}
        onPageChange={this.handlePaginationChange}
        totalPages={totalPages}
        floated="right"
      />
    );
  }

  renderTotal() {
    const count = this.filesStore.total;
    const niceCount = niceNumber(count);

    return (
      <Header as="h3" className="color-grey mt1 mb0 flex-auto">
        Loaded Entries :{' '}
        <Label circular size="large">
          {niceCount}
        </Label>
      </Header>
    );
  }

  renderTable() {
    return (
      <div className="mt4">
        <Segment.Group>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
          <Segment clearing>
            {this.renderTotal()}
            <div className="flex justify-end">{this.renderBackButton()}</div>
            {this.renderContent()}
          </Segment>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
        </Segment.Group>
      </div>
    );
  }

  /* renderBreadcrumb() {
    const isSearchOn = this.filesStore.isSearchOn;
    const selectedPrefix = this.filesStore.selectedPrefix;
    const levels = selectedPrefix.split('/');
    // To get rid of the last empty string in levels array
    levels.pop();
    return (
      <Breadcrumb>
        {isSearchOn && (
          <>
            <Breadcrumb.Section>Search Result</Breadcrumb.Section>
            <Breadcrumb.Divider />
          </>
        )}
        {levels.map((level, i, { length }) => {
          if (i + 1 === length) {
            return (
              <>
                <Breadcrumb.Section active>{level}</Breadcrumb.Section>
              </>
            );
          }
          return (
            <>
              <Breadcrumb.Section>{level}</Breadcrumb.Section>
              <Breadcrumb.Divider />
            </>
          );
        })}
      </Breadcrumb>
    );
  } */

  handleBack() {
    let backParent = '';
    let parts = this.selectedPrefix.endsWith('/')
      ? this.selectedPrefix.slice(0, -1).split('/')
      : this.selectedPrefix.split('/');
    if (parts.length > 1) {
      parts.pop(parts.length);
      backParent = _.join(parts, '/');
    } else {
      backParent = '';
    }
    this.handlePrefix(backParent);
  }

  renderBackButton() {
    if (this.selectedPrefix !== '') {
      return (
        <div>
          <Button
            floated="right"
            size="mini"
            compact
            color="blue"
            className="mb2"
            onClick={() => {
              this.handleBack();
            }}
          >
            Back
          </Button>
        </div>
      );
    }
    return null;
  }

  /* async renderFileDownload(file) {
    const urlLink = await this.filesStore.retrieveFileAccesUrl(this.filesStore.runId, file.fullPath);

    const link = document.createElement('a');
    link.href = urlLink;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  } */

  async selectAllFiles() {
    const files = this.filesStore.files;
    _.map(files, file => {
      if (file.fileType === 'file' && !this.props.selectedFiles.includes(file.fullPath)) {
        this.props.onFileSelect(null, { value: file.fullPath });
      }
    });
  }

  renderContent() {
    if (this.filesStore.isEmpty) {
      return <div>No files uploaded</div>;
    } 
      const result = this.results;
      // const filesStore = this.getFilesStore();
      const renderRow = file => {
        if (file.fileType === 'file') {
          return (
            <Table.Row key={file.fileName}>
              <Table.Cell>
                <Checkbox
                  checked={this.props.selectedFiles.includes(file.fullPath)}
                  onChange={this.props.onFileSelect}
                  value={file.fullPath}
                />
              </Table.Cell>
              <Table.Cell>{file.fileName}</Table.Cell>
              <Table.Cell>{formatBytes(file.size)}</Table.Cell>
            </Table.Row>
          );
        } 
          return (
            <Table.Row key={file.fileName}>
              <Table.Cell>
                {' '}
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    let finalPrefix =
                      this.selectedPrefix !== '' ? `${this.selectedPrefix}${file.fileName}` : `${file.fileName}`;
                    this.handlePrefix(finalPrefix);
                  }}
                >
                  {file.fileName}
                </a>
              </Table.Cell>
              <Table.Cell>{this.filesStore.isSearchOn && file.fullPath}</Table.Cell>
              <Table.Cell />
            </Table.Row>
          );
        
      };

      return (
        <>
          <Dimmer.Dimmable dimmed={false}>
            <Dimmer active={false}>
              <Loader />
            </Dimmer>
            <Button
              floated="right"
              size="mini"
              compact
              color="blue"
              className="mb2"
              onClick={() => {
                this.selectAllFiles();
              }}
            >
              Select all
            </Button>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell rowSpan="2" />
                  <Table.HeaderCell rowSpan="2">Name</Table.HeaderCell>
                  <Table.HeaderCell rowSpan="2">Size</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>{_.map(result, file => renderRow(file))}</Table.Body>
            </Table>
          </Dimmer.Dimmable>
        </>
      );
    
  }
}

export default observer(FilesTable);
