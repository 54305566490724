/* eslint-disable import/prefer-default-export */
import { types, applySnapshot } from 'mobx-state-tree';

import { FilesStore } from './PipelineRunFilesStore';

const RunParameter = types.model('RunParameter', {
  genome: types.maybeNull(types.string),
});

const PipelineRun = types
  .model('PipelineRun', {
    id: types.identifier,
    name: types.maybeNull(types.string),
    studyId: types.maybeNull(types.string),
    pipelineId: types.maybeNull(types.string),
    parameters: types.maybeNull(RunParameter),
    inputFiles: types.optional(types.array(types.string), []),
    created_time: '',
    updated_time: '',
    status: types.maybeNull(types.string),
    filesStore: types.maybe(FilesStore),
  })
  .actions(self => ({
    setPipelineRun(run) {
      applySnapshot(self, run);
    },
    getFilesStore() {
      if (!self.filesStore) {
        self.filesStore = FilesStore.create({ runId: self.id });
      }
      return self.filesStore;
    },
  }));

export { PipelineRun };
