import React from 'react';
import { observer, inject } from 'mobx-react';
import TimeAgo from 'react-timeago';
import { Header, Label, Radio } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';

class PipelineCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editModeOn: false,
      isProcessing: false,
    };
  }

  getPipeline() {
    return this.props.pipeline;
  }

  /* enableEditMode = () => {
    this.setState({ editModeOn: true });
  }; */

  /* getUserDisplayNameService() {
    return this.props.userDisplayName;
  } */

  /* resetForm = () => {
    this.setState({ editModeOn: false, isProcessing: false });
  }; */

  render() {
    //const displayNameService = this.getUserDisplayNameService();
    const { id, name, createdAt, status } = this.getPipeline();
    return (
      <>
        <Label attached="top left">Analysis Pipeline</Label>
        <div className="flex mb1">
          <Radio className="mr1" checked={this.props.selected === id} />
          <Header as="h3" color="grey" className="mt0 flex-auto ellipsis">
            Pipeline ID: {id}
            <br />
            Pipeline Name: {name}
            <br />
            <Header.Subheader className="fs-9">
              created <TimeAgo date={Date.parse(createdAt)} />
            </Header.Subheader>
          </Header>
          <br />
          <div className="flex-auto mb1 mr4 right-align">
            <Label basic size="mini" color={status === 'Available' ? 'green' : 'blue'}>
              {status || 'Unknown'}
            </Label>
          </div>
        </div>
      </>
    );
  }
}

export default inject()(withRouter(observer(PipelineCard)));
