/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import c from 'classnames';
import { Message } from 'semantic-ui-react';
import purify from 'dompurify';

// expected props
// - field (via props), this is the mobx form field object
const Component = observer(({ field }) => {
  const { extra = {} } = field;
  const explain = (extra || {}).explain;
  const warn = (extra || {}).warn;
  const hasExplain = !!explain;
  const hasWarn = !!warn;

  return (
    <>
      {hasExplain && (
        <div className={c('field', 'mb2')} dangerouslySetInnerHTML={{ __html: purify.sanitize(explain) }} />
      )}
      {hasWarn && (
        <Message className="field" color="brown">
          <b className="mr1">Warning</b>
          {warn}
        </Message>
      )}
    </>
  );
});

export default Component;
