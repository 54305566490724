import React from 'react';
import { Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getCreateRunForm } from '../../models/forms/CreatePipelineRun';
//import { getAwsAccount, getIndex, getProject } from '../../helpers/api';
import { displayError } from '../../helpers/notification';
import { getOptionsFromRules } from '../../helpers/utils';
import { isStoreError, isStoreLoading } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import Dropdown from '../helpers/fields/DropDown';
import Form from '../helpers/fields/Form';
import Input from '../helpers/fields/Input';
import FilesTable from '../studies/FilesTable';

class CreatePipelineRun extends React.Component {
  modalOpen = false;
  selectedFiles = [];
  studyFilesStore = null;	

  constructor(props) {
    super(props);

    makeObservable(this, {
      modalOpen: observable,
      studyFilesStore: observable,
      selectedFiles: observable,
    });

    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
    this.stores = [this.props.studiesStore, this.props.pipelinesStore];

    this.state = {
      pipelines: [],
    };
  }

  componentDidMount() {
    this.props.studiesStore.load();
    this.props.pipelinesStore.load();
  }

  cleanModal() {
    runInAction(() => {
      this.selectedFiles = [];
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const formValues = form.values();
      formValues.inputPaths = this.selectedFiles;

      await this.props.pipelineRunsStore.createRun(formValues);

      form.clear();
      this.cleanModal();
    } catch (error) {
      displayError(error);
    }
  };

  handleStudyChange = async (event, data) => {
    runInAction(() => {
      this.studyFilesStore = this.props.studiesStore.getStudyFilesStore(data.value);
    });
  };

  handleOnFileSelect = async (event, data) => {
    const fullPath = data.value;
    runInAction(() => {
      if (this.selectedFiles.includes(fullPath)) {
        this.selectedFiles = this.selectedFiles.filter(f => f !== fullPath);
      } else {
        this.selectedFiles.push(fullPath);
      }
    });
  };

  render() {
    const store = this.stores;
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = (
        <Button disabled type="button">
          Create Run
        </Button>
      );
    } else {
      content = (
        <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
          <div className="mt2 animated fadeIn">
            <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
              Create Pipeline Run
            </BaseHeader>
            <div className="mx3 animated fadeIn">{this.renderCreateRunForm()}</div>
          </div>
        </Modal>
      );
    }
    return content;
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
        type="button"
      >
        Create Run
      </Button>
    );
  }

  renderCreateRunForm() {
    const form = getCreateRunForm();
    const pipelineIds = this.props.pipelinesStore.list.map(w => {
      return { key: w.id, value: w.id, text: w.id };
    });
    const studies = this.props.studiesStore.list.map(s => {
      return { key: s.id, value: s.id, text: s.name };
    });
    const genomeOptions = getOptionsFromRules(form.$('genome').rules);

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          return (
            <>
              <Input field={form.$('name')} />
              <Dropdown field={form.$('pipelineId')} options={pipelineIds} fluid selection />
              <Dropdown field={form.$('studyId')} options={studies} fluid selection onChange={this.handleStudyChange} />
              {this.studyFilesStore && (
                <FilesTable
                  filesStore={this.studyFilesStore}
                  onFileSelect={this.handleOnFileSelect}
                  selectedFiles={this.selectedFiles}
                />
              )}
              <Dropdown field={form.$('genome')} options={genomeOptions} fluid selection />

              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Create Run
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('studiesStore', 'pipelinesStore', 'pipelineRunsStore')(observer(CreatePipelineRun));
