/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import toastr from 'toastr';

const toastrCSS = { width: '500px', 'max-width': '500px' };

// Note that our default timeout value is zero for errors because
// we don't want them to automatically disappear. The caller can
// override this default behavior if needed.
function displayError(msg, error, timeOut = '0') {
  const e = toastr.error(toMessage(msg, error), 'We have a problem!', { ...toasterErrorOptions, timeOut });
  if (e) e.css(toastrCSS);
  if (error) console.error(msg, error);
  if (_.isError(msg)) console.error(msg);
}

function displayWarning(msg, error, timeOut = '20000') {
  const w = toastr.warning(toMessage(msg, error), 'WARNING!', { ...toasterWarningOptions, timeOut });
  if (w) w.css(toastrCSS);
  if (error) console.error(msg, error);
  if (_.isError(msg)) console.error(msg);
}

function displaySuccess(msg, title = 'Submitted!', timeOut = '3000') {
  const s = toastr.success(toMessage(msg), title, { ...toasterSuccessOptions, timeOut });
  if (s) s.css(toastrCSS);
}

function displayFormErrors(form) {
  const map = form.errors();
  const lines = [];
  Object.keys(map).forEach(key => {
    if (map[key]) lines.push(map[key]);
  });

  if (lines.length === 0) return displayError('The form submission has a problem.');
  const isPlural = lines.length > 1;
  const message = `There ${isPlural ? 'are issues' : 'is an issue'} with the form:`;
  return displayError([message, ...lines]);
}

function toMessage(msg, error) {
  if (_.isError(msg)) {
    return `${msg.message || msg.friendly} <br/>&nbsp;`;
  }

  if (_.isError(error)) {
    return `${msg} - ${error.message} <br/>&nbsp;`;
  }

  if (_.isArray(msg)) {
    const messages = msg;
    const size = _.size(messages);

    if (size === 0) {
      return 'Unknown error <br/>&nbsp;';
    }
    if (size === 1) {
      return `${messages[0]}<br/>&nbsp;`;
    }
    const result = [];
    result.push('<br/>');
    result.push('<ul>');
    _.forEach(messages, message => {
      result.push(`<li style="margin-left: -20px;">${message}</li>`);
    });
    result.push('</ul><br/>&nbsp;');

    return result.join('');
  }

  if (_.isEmpty(msg)) return 'Unknown error <br/>&nbsp;';

  return `${msg} <br/>&nbsp;`;
}

// For details of options, see https://github.com/CodeSeven/toastr
//
// closeButton:       Enable a close button
// debug:             Emit debug logs to the console
// newestOnTop:       Show newest toast at top or bottom (top is default)
// progressBar:       Visually indicate how long before a toast expires
// positionClass:     CSS position style e.g. toast-top-center, toast-bottom-left
// preventDuplicates: Prevent identical toasts appearing (based on content)
// timeOut:           How long the toast will display without user interaction (ms)
// extendedTimeOut:   How long the toast will display after a user hovers over it (ms)

const toasterErrorOptions = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  timeOut: '0', // do not auto-hide errors
  extendedTimeOut: '0',
};

const toasterWarningOptions = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  timeOut: '20000',
  extendedTimeOut: '50000',
};

const toasterSuccessOptions = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  timeOut: '3000',
  extendedTimeOut: '10000',
};

export { displayError, displayWarning, displaySuccess, displayFormErrors };
