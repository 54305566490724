/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { useLocation, useNavigate, useParams } from 'react-router-dom';

// courtesy of https://github.com/remix-run/react-router/discussions/8510
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const match = { params };
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        match={match}
        params={params}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function createLinkWithSearch({ location, pathname, search }) {
  return {
    pathname,
    search: search || location.search,
    hash: location.hash,
    state: location.state,
  };
}

function createLink({ location, pathname }) {
  return {
    pathname,
    hash: location.hash,
    state: location.state,
  };
}

function reload() {
  setTimeout(() => {
    window.location.reload();
  }, 150);
}

/**
 * A generic goto function creator function that returns a go to function bound to the given react component.
 *
 * See below snippet as an example for using this function from within some react component
 * containing "location" and "history" props.
 *
 * const goto = gotoFn(this);
 * goto('/some-path');
 *
 * @param reactComponent A react component that has "location" and "history" props as injected via the "withRouter" function.
 * @returns {{new(...args: any[]): any} | ((...args: any[]) => any) | OmitThisParameter<goto> | goto | any | {new(...args: any[]): any} | ((...args: any[]) => any)}
 */
function gotoFn(reactComponent) {
  function goto(pathname) {
    const location = reactComponent.props.location;
    const link = createLink({ location, pathname });

    reactComponent.props.history.push(link);
  }
  return goto.bind(reactComponent);
}

export { createLink, createLinkWithSearch, reload, gotoFn, withRouter };
