/* eslint-disable import/prefer-default-export */
import { types, applySnapshot } from 'mobx-state-tree';

const Pipeline = types
  .model('Pipeline', {
    id: types.maybeNull(types.string),
    name: types.maybeNull(types.string),	  
    arn: types.maybeNull(types.string),
    createdAt: '',
    status: types.maybe(types.enumeration('status', ['CREATING', 'ACTIVE', 'UPDATING', 'DELETED', 'FAILED'])),
  })
  .actions(self => ({
    setPipeline(pipeline) {
      applySnapshot(self, pipeline);
    },
  }));

export { Pipeline };
