import { BaseStore } from '../BaseStore';

const PipelineStore = BaseStore.named('PipelineStore')
  .props({
    id: '',
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {/**/},
      cleanup: () => {
        superCleanup();
      },
    };
  });


// Note: Do NOT register this in the global context, if you want to gain access to an instance
//       use environmentsStore.getEnvironmentStore()
// eslint-disable-next-line import/prefer-default-export
export { PipelineStore };
