import { getParent } from 'mobx-state-tree';

import { BaseStore } from '../BaseStore';

// ==================================================================
// StudyStore
// ==================================================================
const StudyStore = BaseStore.named('StudyStore')
  .props({
    studyId: '',
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const parent = getParent(self, 2);
        parent.addStudy({ studyId: self.studyId });
      },

      cleanup: () => {
        superCleanup();
      },
    };
  })

  .views(self => ({
    get study() {
      const parent = getParent(self, 2);
      // const w = parent.study;
      return parent;
    },
  }));

// Note: Do NOT register this in the global context, if you want to gain access to an instance
//       use studiesStore.getStudyStore()
// eslint-disable-next-line import/prefer-default-export
export { StudyStore };
