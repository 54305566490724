import _ from 'lodash';
import React from 'react';
import { makeObservable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Menu, Icon, Input } from 'semantic-ui-react';
import { withRouter } from '../helpers/routing';
import { displayError } from '../helpers/notification';
import { branding } from '../helpers/settings';

class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    makeObservable(this, {
      handleLogout: action,
      handleChange: action,
    });
  }

  goto = pathname => () => {
    /*const location = this.props.location;
    const link = createLink({
      location,
      pathname,
    });

    this.props.history.push(link);*/
    this.props.navigate(pathname);
  };

  handleLogout = async event => {
    event.preventDefault();
    event.stopPropagation();
    try {
      await this.props.authentication.logout();
      window.location = '/';
    } catch (error) {
      displayError(error);
    }
  };

  handleChange = async event => {
    event.preventDefault();
    event.stopPropagation();

    const key = event.target.value;
    this.props.userJwtStore.setJwt(key);
  };

  render() {
    const pathname = _.get(this.props.location, 'pathname', '');
    const is = value => _.startsWith(pathname, value);

    const items = [
      { title: 'Pipelines', url: '/pipelines', icon: 'settings', show: true },
      { title: 'Runs', url: '/runs', icon: 'tasks', show: true },
      // Menu Item Insertion Point (do not change this text, it is being used by hygen cli)
    ];

    return [
      <Menu vertical inverted fixed="left" icon="labeled" key="ml1" style={{ overflowY: 'auto' }}>
        <Menu.Item name=" " style={{ height: '40px' }} />
        {_.map(
          items,
          (item, index) =>
            item.show &&
            !item.admin && (
              <Menu.Item key={index} active={is(item.url)} onClick={is(item.url) ? undefined : this.goto(item.url)}>
                <Icon name={item.icon} size="mini" />
                <span className="fs-7">{item.title}</span>
              </Menu.Item>
            ),
        )}
        <Menu.Item name=" " style={{ height: '40px' }} />
      </Menu>,

      <Menu vertical inverted fixed="bottom" icon="labeled" key="ml4">
        <Menu.Item name=" " style={{ height: '40px' }}>
          {branding.main.version}
        </Menu.Item>
      </Menu>,

      <Menu inverted color="black" fixed="top" className="box-shadow zindex-1500" key="ml2">
        <Menu.Item className="m0 pt2 pl2 pr2" style={{ height: '30px' }}>
          {/* <Image size="mini" src={logo} className="mr1" style={{ maxHeight: '20px' }}/> */}
          {branding.main.title}
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Input placeholder="jwt" onChange={this.handleChange} />
          </Menu.Item>

          <Menu.Item>
            <Icon name="id badge" />
          </Menu.Item>

          <Menu.Item name="logout" onClick={this.handleLogout} />
        </Menu.Menu>
      </Menu>,
      <div
        className="mainLayout fit animated fadeIn"
        style={{
          paddingTop: '40px',
          paddingLeft: '84px',
          paddingBottom: '100px',
        }}
        key="ml3"
      >
        {this.props.children}
      </div>,
    ];
  }
}

export default inject('userJwtStore')(withRouter(observer(MainLayout)));
