import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { branding } from './helpers/settings';
import { withRouter } from './helpers/routing';
import MainLayout from './parts/MainLayout';
import PipelinesList from './parts/pipelines/PipelinesList';
import PipelineRunsList from './parts/pipelineRuns/PipelineRunsList';
// Import Insertion Point (do not change this text, it is being used by hygen cli)

class App extends Component {
  componentDidMount() {
    document.title = branding.page.title;
  }

  // edited by 2359 - added routes for AMI
  renderApp() {
    // See https://reacttraining.com/react-router/web/api/withRouter
    const { location } = this.props;
    const defaultLocation = {
      pathname: '/pipelines',
      search: location.search, // we want to keep any query parameters
      hash: location.hash,
      state: location.state,
    };

    return (
      <MainLayout>
        <Routes>
          <Route exact path="/" element={<Navigate to={defaultLocation} />} />
          <Route path="/pipelines" element={<PipelinesList />} />
          <Route path="/runs" element={<PipelineRunsList />} />
          {/* Route Insertion Point (do not change this text, it is being used by hygen cli) */}
        </Routes>
      </MainLayout>
    );
  }

  renderLayout() {
    return <MainLayout />;
  }

  /* renderProgress() {
    return (
      <Container text className="pt4">
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            Please wait!
          </Message.Content>
        </Message>
      </Container>
    );
  }

  renderError() {
    const { app } = this.props;
    const message = `Something went wrong and the error message is ${app.startup.errorMessage}.  Please refresh your browser.`;
    return (
      <Container text className="pt4">
        <Message negative className="clearfix">
          <Message.Header>Oops!</Message.Header>
          <p>{message}</p>
        </Message>
      </Container>
    );
  }

  renderWaitRegistration() {
    return null;
  } */

  render() {
    //const { app } = this.props;
    let content = null;

    if (this.props.userJwtStore.empty) {
      content = this.renderLayout();
    } else {
      content = this.renderApp();
    }

    /*
    if (isStoreError(app.startup)) {
      content = <Authenticate />;
    } else if (isStoreLoading(app.startup)) {
      content = this.renderProgress();
    } else if (isStoreReady(app.startup) && app.userAuthenticated && !app.userRegistered) {
      content = this.renderWaitRegistration();
    } else if (isStoreReady(app.startup) && app.userAuthenticated && app.userRegistered) {
      content = this.renderApp();
    } else {
      content = <Authenticate />;
    }
	*/

    return content;
  }
}

export default inject('userJwtStore', 'app')(withRouter(observer(App)));
