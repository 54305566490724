/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';
import Startup from './Startup';

const App = types
  .model('App', {
    userAuthenticated: types.boolean,
    userRegistered: types.boolean,
    startup: Startup,
  })
  .actions(self => ({
    start: async () => {
      await self.startup.load();
    },

    setUserAuthenticated(flag) {
      self.userAuthenticated = flag;
    },

    setUserRegistered(flag) {
      self.userRegistered = flag;
    },

    // this method is called by the Cleaner
    cleanup() {
      self.setUserAuthenticated(false);
      self.setUserRegistered(false);
    },
  }));

function registerModels(globals) {
  globals.app = App.create({ userAuthenticated: false, userRegistered: false, startup: {} }, globals);
}

export { App, registerModels };
