/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { observer } from 'mobx-react';

// expected props
// - field (via props), this is the mobx form field object
const Component = observer(({ field }) => {
  const { error = '' } = field;
  const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError

  if (!hasError) return null;
  return <div className="ui pointing basic label">{error}</div>;
});

export default Component;
