import { types } from 'mobx-state-tree';
import { removeNulls } from '../../helpers/utils';

const Study = types
  .model('Study', {
    id: '',
    name: '',
    description: '',
  })
  .actions(self => ({
    runInAction(fn) {
      return fn();
    },
    setStudy(rawStudy) {
      removeNulls(rawStudy);
      self.id = rawStudy.id;
      self.name = rawStudy.name;
      self.description = rawStudy.description;
    },
  }));

export { Study };
