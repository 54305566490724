/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { BaseStore } from '../BaseStore';
import { setIdToken } from '../../helpers/api';

const UserJwtStore = BaseStore.named('UserJwtStore')
  .props({
    jwt: types.maybe(types.string),
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {/**/},
      cleanup: () => {
        self.jwt = undefined;
        superCleanup();
      },
      setJwt: jwt => {
        self.runInAction(() => {
          self.jwt = jwt;
          setIdToken(jwt);
        });
      },
    };
  })

  .views(self => ({
    get empty() {
      return _.isEmpty(self.jwt);
    },
  }));

function registerModels(globals) {
  globals.userJwtStore = UserJwtStore.create({}, globals);
}

export { UserJwtStore, registerModels };
