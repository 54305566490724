import _ from 'lodash';
import {  types } from 'mobx-state-tree';
import { listPipelineRuns, createPipelineRun } from '../../helpers/api';
import { consolidateToMap } from '../../helpers/utils';
import { BaseStore } from '../BaseStore';
import { PipelineRun } from './PipelineRun';
import { PipelineRunStore } from './PipelineRunStore';

const PipelineRunsStore = BaseStore.named('PipelineRunsStore')
  .props({
    runs: types.optional(types.map(PipelineRun), {}),
    runStores: types.optional(types.map(PipelineRunStore), {}),
    tickPeriod: 900 * 1000,
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        let runs = await listPipelineRuns();
        self.runInAction(() => {
          consolidateToMap(self.runs, runs, (existing, newItem) => {
            existing.setPipelineRun(newItem);
          });
        });
      },
      async getPipelineRunStore(id) {
        let entry = self.runStores.get(id);
        if (!entry) {
          // Lazily create the store
          self.runStores.set(id, PipelineRunStore.create({ id }));
          entry = self.runStores.get(id);
        }

        return entry;
      },
      addRun(rawRun) {
        const id = rawRun.id;
        const previous = self.runs.get(id);
        self.runInAction(() => {
          if (!previous) {
            self.runs.put(rawRun);
          } else {
            previous.setRun(rawRun);
          }
        });
        return self.runs.get(id);
      },

      async createRun(rawRun) {
        const result = await createPipelineRun(rawRun);
        self.runInAction(() => {
          self.addRun(result);
        });
        return self.getRun(result.id);
      },
      cleanup: () => {
        superCleanup();
      },
	    
    };
  })

  .views(self => ({
    get empty() {
      return self.runs.size === 0;
    },

    get total() {
      return self.runs.size;
    },

    get list() {
      const result = [];
      self.runs.forEach(run => result.push(run));

      return _.reverse(_.sortBy(result, ['createdAt']));
    },
    getRun(id) {
      return self.runs.get(id);
    },
  }));

function registerModels(globals) {
  globals.pipelineRunsStore = PipelineRunsStore.create({}, globals);
}

export { PipelineRunsStore, registerModels };
