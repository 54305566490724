import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { listPipelines, createPipelineRun } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { Pipeline } from './Pipeline';
import { PipelineStore } from './PipelineStore';

const PipelinesStore = BaseStore.named('PipelinesStore')
  .props({
    pipelines: types.optional(types.map(Pipeline), {}),
    pipelineStores: types.optional(types.map(PipelineStore), {}),
    tickPeriod: 900 * 1000,
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        let pipelines = await listPipelines();
        self.runInAction(() => {
          let result = {};
          _.forEach(pipelines, pipeline => {		
            result[pipeline.id] = Pipeline.create(pipeline);
          });
          self.pipelines.replace(result);
        });
      },
      async getPipelineStore(id) {
        let entry = self.pipelineStores.get(id);
        if (!entry) {
          // Lazily create the store
          self.pipelineStores.set(id, PipelineStore.create({ id }));
          entry = self.pipelineStores.get(id);
        }

        return entry;
      },
      async createRun(pipelineId, params) {
        const _run = await createPipelineRun(pipelineId, params.inputStudy, params.outputStudy, params);
      },
      cleanup: () => {
        superCleanup();
      },	    
    };
  })

  .views(self => ({
    get empty() {
      return self.pipelines.size === 0;
    },

    get total() {
      return self.pipelines.size;
    },

    get list() {
      const result = [];
      self.pipelines.forEach(pipeline => result.push(pipeline));

      return _.reverse(_.sortBy(result, ['createdAt']));
    },
  }));

function registerModels(globals) {
  globals.pipelinesStore = PipelinesStore.create({}, globals);
}

export { PipelinesStore, registerModels };
