/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Header, Label, Tab, Dimmer, Loader } from 'semantic-ui-react';

class RowBase extends React.Component {
  getTabPanes() {
    return [];
  }

  getData() {
    return {};
  }

  render() {
    const busy = this.props.busy;
    const data = this.getData();

    return (
      <div>
        {this.renderIndexLabel(data.pos)}
        <Dimmer.Dimmable dimmed={busy} className="m0 p0">
          <Dimmer active={busy} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <div>
            {this.renderHeader(data)}
            {this.renderTab(data)}
          </div>
        </Dimmer.Dimmable>
      </div>
    );
  }

  renderIndexLabel(pos) {
    return (
      <Label color="grey" size="mini" ribbon>
        {pos}
      </Label>
    );
  }

  renderHeader(data) {
    return (
      <Header as="h2" color="grey" className="mt0">
        <a href={data.url} rel="noopener noreferrer" target="_blank">
          {data.name} <span className="pl2 font-size-small-9">{data.id}</span>
        </a>
      </Header>
    );
  }

  renderTab(data) {
    const panes = this.getTabPanes(data);

    return <Tab className="mt2" menu={{ secondary: true, pointing: true }} panes={panes} />;
  }

  /* renderMetadataPane() {
    const meta = this.getData().meta;
    return (
      <Table definition size="small">
        <Table.Body>
          {_.map(meta, (item, index) => (
            <Table.Row key={index} verticalAlign="top">
              <Table.Cell width={2}>{item.name}</Table.Cell>
              <Table.Cell>{item.value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  } */
}

export default observer(RowBase);
