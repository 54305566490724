/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';

// We can only do this trick thanks to Webpack
// see https://github.com/webpack/docs/wiki/context

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

function postRegister(globals) {
  // requires and returns all modules that match
  const modules = requireAll(require.context('../models', true, /^\.\/.*\.js$/));

  // is an array containing all the matching modules
  _.forEach(modules, model => {
    if (_.isFunction(model.postRegister)) model.postRegister(globals);
  });
}

export default postRegister;
