/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { getStudyContents } from '../../helpers/api';
import { BaseStore } from '../BaseStore';

const File = types.model('File', {
  fileType: types.string,
  fileName: types.string,
  size: types.optional(types.integer, 0),
  fullPath: types.maybeNull(types.string),
});

const StudyFilesStore = BaseStore.named('FilesStore')
  .props({
    currentPage: 1,
    pageSize: 10,
    total: 0,
    progress: 0,
    studyId: '',
    selectedPrefix: '',
    parentPrefix: '',
    files: types.array(File),
    visibleRecords: types.optional(types.array(File), []),
    filter: '',
  })

  .actions(self => {
    // save the base implementation of clear
    const superCleanup = self.cleanup;

    return {
      async doLoad({ page = 1, pageSize = 10, reload = false, selectedPrefix } = {}) {
        self.currentPage = page;
        self.pageSize = pageSize;
        self.selectedPrefix = typeof selectedPrefix !== 'undefined' && selectedPrefix !== '' ? selectedPrefix : '';

        if (reload) {
          // Retrieve files
          self.progress = 0;
          self.total = 0;
          self.currentPage = 1;
          self.visibleRecords = [];

          let result = {};
            let fullDirList = {};

          // retrieve everything in current prefix
          fullDirList = await getStudyContents(self.studyId, self.selectedPrefix);
          let mergedFiles = fullDirList;
          result = fullDirList;

          if (result === null || result.length === 0) {
            self.runInAction(() => {
              self.files = [];
            });
          } else {
            let files = mergedFiles
              .sort((fileA, fileB) => fileA.fileType.localeCompare(fileB.fileType))
              .map(file => {
                return { ...file };
              });

            // Update store
            self.runInAction(() => {
              self.parentPrefix = result.parentPrefix;
              self.files.replace(files);
              const filtered =
                self.filter === '' ? self.files : self.files.filter(file => file.fileName.includes(self.filter));
              self.total = self.files.length;
              let num = self.currentPage * self.pageSize;
              self.visibleRecords.replace(_.cloneDeep(filtered.slice(num - self.pageSize, num)));
              self.progress = 100;
            });
          }
        } else {
          self.runInAction(() => {
            self.progress = 0;
            let num = self.currentPage * self.pageSize;
            console.log(self.filter);
            const filtered =
              self.filter === '' ? self.files : self.files.filter(file => file.fileName.includes(self.filter));
            self.progress = 100;
            self.visibleRecords.replace(_.cloneDeep(filtered.slice(num - self.pageSize, num)));
          });
        }
      },

      cleanup: () => {
        self.runInAction(() => {
          self.files.clear();
          superCleanup();
          self.clearSearch();
          self.currentPage = 1;
          self.total = 0;
          self.visibleRecords = [];
          self.selectedFiles = [];
          self.progress = 0;
          self.parentCollections = [];
          self.filter = '';
        });
      },

      empty() {
        self.files.clear();
      },

      setFilter: val => {
        self.runInAction(() => {
          self.filter = val;
        });
      },
    };
  })
  .views(self => ({
    get isEmpty() {
      return self.files.length === 0;
    },
    get totalPages() {
      return self.total === 0 ? 1 : Math.ceil(self.total / self.pageSize);
    },
  }));

// Note: Do NOT register this in the global context, if you want to gain access to an instance
//       use study.getFilesStore()
export { StudyFilesStore }; // eslint-disable-line import/prefer-default-export
