import { observer, inject } from 'mobx-react';
import { makeObservable } from 'mobx';
import { Header, Label, Grid, Tab } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';

import RowBase from './RowBase';
import FilesTable from './FilesTable';

const COLOR_PALETTE = {
  CREATED: 'olive',
  PENDING: 'olive',
  STARTING: 'orange',
  RUNNING: 'blue',
  STOPPING: 'orange',
  COMPLETED: 'green',
  DELETED: 'red',
  CANCELLED: 'red',
  FAILED: 'red',
};

class PipelineRunCard extends RowBase {
  constructor(props) {
    super(props);
    makeObservable(this, {});
    this.state = {
      editModeOn: false,
      isProcessing: false,
    };
  }

  getRun() {
    return this.props.run;
  }

  getUserDisplayNameService() {
    return this.props.userDisplayName;
  }

  renderHeader() {
    const run = this.getRun();
    return (
      <Header as="h2" color="grey" className="mt0">
        <Grid>
          <Grid.Column width={12}>
            {run.name} <span className="pl2 font-size-small-9">{run.id}</span>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {this.renderRunStatusLabel(run)}
          </Grid.Column>
        </Grid>
      </Header>
    );
  }

  getTabPanes() {
    const run = this.getRun();
    let panes = [
      {
        menuItem: 'Run Details',
        render: () => (
          <Tab.Pane basic attached={false}>
            {this.renderDescriptionPane(run)}
          </Tab.Pane>
        ),
      },
    ];

    if (run.status === 'COMPLETED') {
      panes.push({
        menuItem: 'Output Results',
        render: () => (
          <Tab.Pane basic attached={false}>
            <FilesTable run={run} />
          </Tab.Pane>
        ),
      });
    }

    return panes;
  }

  renderDescriptionPane(run) {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>Run name:</Grid.Column>
          <Grid.Column>{run.name}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Run status:</Grid.Column>
          <Grid.Column>{run.status}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Run created at:</Grid.Column>
          <Grid.Column>{run.created_time}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Last updated:</Grid.Column>
          <Grid.Column>{run.updated_time}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Genome:</Grid.Column>
          <Grid.Column>{run.parameters.genome}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Run input files:</Grid.Column>
          <Grid.Column>
            <ul>
              {run.inputFiles.map(f => (
                <li>{f}</li>
              ))}
            </ul>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderRunStatusLabel(run) {
    const statusColor = COLOR_PALETTE[run.status];
    return (
      <Label color={statusColor} size="mini">
        {run.status}
      </Label>
    );
  }
}

export default inject()(withRouter(observer(PipelineRunCard)));
