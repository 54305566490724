import _ from 'lodash';
import React from 'react';
import { makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Header, Icon, Segment, Container, Label } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import { isStoreLoading } from '../../models/BaseStore';
import { swallowError } from '../../helpers/utils';

import PipelineRunCard from './PipelineRunCard';
import CreatePipelineRun from './CreatePipelineRun';

class PipelineRunsList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
    this.state = { list: [] };
  }

  componentDidMount() {
    const store = this.getStore();
    swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {}

  getStore() {
    const store = this.props.pipelineRunsStore;
    return store;
  }

  // async startRun() {}

  render() {
    const list = this.getStore().list;
    const store = this.getStore();

    return (
      <Container className="mt3 animated fadeIn">
        {this.renderTitle()}
        {list.length > 0 && isStoreLoading(store) ? <ProgressPlaceHolder segmentCount={3} /> : this.renderActions()}
        {list.length > 0 && isStoreLoading(store) ? <ProgressPlaceHolder segmentCount={3} /> : this.renderMain(list)}
      </Container>
    );
  }

  /* renderEmpty() {
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No Runs
        </Header>
      </Segment>
    );
  } */

  renderActions() {
    return (
      <div className="mb3 flex">
        <CreatePipelineRun pipelineRunsStore={this.props.pipelineRunsStore} />
      </div>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="server" className="align-top" />
          <Header.Content className="left-align"> Runs {this.renderTotal()}</Header.Content>
        </Header>
      </div>
    );
  }

  renderTotal() {
    return <Label circular>{this.getStore().list.length}</Label>;
  }

  renderMain(list) {
    return this.getStore().list.length === 0 ? (
      'You do not have any  runs yet.'
    ) : (
      <div>
        {_.map(list, item => (
          <Segment className="p3 mb2  cursor-pointer" clearing key={item.id} data-instance={item.id}>
            <PipelineRunCard run={item} />
          </Segment>
        ))}
      </div>
    );
  }
}

export default inject('pipelineRunsStore')(withRouter(observer(PipelineRunsList)));
