import _ from 'lodash';
import { createRoot } from 'react-dom/client';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { Message, Icon, Container } from 'semantic-ui-react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'typeface-lato';
import './css/basscss-important.css';
import './css/semantic.min.css';
import './css/animate.css';
import 'toastr/build/toastr.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './css/index.css';

import { initializeGlobals } from './globals';
import App from './App';

// Disabling service worker
serviceWorkerRegistration.unregister();

// Enable mobx strict mode, changes to state must be contained in actions
configure({ enforceActions: 'always' });

const globals = initializeGlobals();

const container = document.getElementById('root');
const root = createRoot(container);

// Render the main App
function renderApp() {
  root.render(
    <Provider {...globals}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
  );
}

// Render a progress message
function renderProgress() {
  root.render(
    <Container text className="pt4">
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          Please wait!
        </Message.Content>
      </Message>
    </Container>,
  );
}

// Render an error message
function renderError(err) {
  const error = _.get(err, 'message', 'Unknown error');
  root.render(
    <Container text className="pt4">
      <Message negative>
        <Message.Header>We have a problem</Message.Header>
        <p>{error}</p>
        <p>See if refreshing the browser will resolve your issue</p>
      </Message>
    </Container>,
  );
}

renderProgress();

// Trigger the app startup sequence
(async () => {
  try {
    await globals.app.start();
    renderApp();
  } catch (err) {
    console.log(err);
    // TODO - check if the code = tokenExpired, then
    // - render a notification error
    // - call cleaner cleanup, this is IMPORTANT
    // - render the app and skip the rest of the renderError logic
    // - doing the above logic will help us have a smooth user experience
    //   when the token has expired. NOTE: this might not be applicable for the
    //   cases where the app requires midway before anything is displayed to the user
    renderError(err);
    try {
      globals.cleaner.cleanup();
    } catch (error) {
      // ignore
      console.log(error);
    }
  }
})();
