import { createForm } from '../../helpers/form';

const createRunFields = {
  // General fields
  name: {
    label: 'Name',
    rules: ['required'],
  },
  pipelineId: {
    label: 'Pipeline ID',
    rules: ['required'],
  },
  studyId: {
    label: 'Study ID',
    rules: ['required'],
  },
  genome: {
    label: 'Genome',
    rules: [
      'required',
      { in: ['GRCh37', 'GRCh38', 'GRCh38_1k', 'GRCh38_2k', 'GRCh38_3k', 'GRCh38_4k', 'GRCh38_PGS002748', 'GRCh38_rerun_202405'] },
    ],
  },
};

const getCreateRunForm = () => {
  return createForm(createRunFields);
};

export { getCreateRunForm }; // eslint-disable-line import/prefer-default-export
