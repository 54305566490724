import _ from 'lodash';
import React from 'react';
import { makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Header, Icon, Segment, Container, Label } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import { isStoreLoading } from '../../models/BaseStore';
import { swallowError } from '../../helpers/utils';

import PipelineCard from './PipelineCard';
import CreatePipelineRun from '../pipelineRuns/CreatePipelineRun';

class PipelinesList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
    this.state = { list: [] };
  }

  componentDidMount() {
    // const store = this.getStore();
    swallowError(this.props.pipelinesStore.load());
    swallowError(this.props.userJwtStore.load());
  }

  componentWillUnmount() {}

  getStore() {
    const store = [this.props.pipelinesStore, this.props.userJwtStore];
    return store;
  }

  // async startRun() {}

  render() {
    const list = this.props.pipelinesStore.list;
    const store = this.getStore();

    return (
      <Container className="mt3 animated fadeIn">
        {this.renderTitle()}
        {list.length > 0 && isStoreLoading(store) ? <ProgressPlaceHolder segmentCount={3} /> : this.renderActions()}
        {list.length > 0 && isStoreLoading(store) ? <ProgressPlaceHolder segmentCount={3} /> : this.renderMain(list)}
      </Container>
    );
  }

  /* renderEmpty() {
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No Pipelines
        </Header>
      </Segment>
    );
  } */

  renderActions() {
    return (
      <div className="mb3 flex">
        <CreatePipelineRun pipelinesStore={this.props.pipelinesStore} />
      </div>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="server" className="align-top" />
          <Header.Content className="left-align"> Pipelines {this.renderTotal()}</Header.Content>
        </Header>
      </div>
    );
  }

  renderTotal() {
    return <Label circular>{this.props.pipelinesStore.list.length}</Label>;
  }

  renderMain(list) {
    return this.props.pipelinesStore.list.length === 0 ? (
      'You do not have any  pipelines yet.'
    ) : (
      <div>
        {_.map(list, item => (
          <Segment className="p3 mb2  cursor-pointer" clearing key={item.id} data-instance={item.id}>
            <PipelineCard pipeline={item} />
          </Segment>
        ))}
      </div>
    );
  }
}

export default inject('pipelinesStore', 'userJwtStore')(withRouter(observer(PipelinesList)));
