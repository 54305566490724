/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getEnv } from 'mobx-state-tree';

import { BaseStore } from './BaseStore';

const Startup = BaseStore.named('Startup').actions(self => ({
  doLoad: async () => {
    // The logic:
    // - ask the authentication model to clear any expired tokens
    // - ask the authentication model for the id token
    //   - if not found, it is either expired or does not exists, in both cases, we will consider this a clean start
    //   - if found,
    //      - set the token on the api object
    //      - ask the userStore to load the user
    //      - if we get an error, display the error and clean everything, very much starting over
    //      - if we don't get an error, then
    //        - finally, setup any preferences store (TODO - future enhancement), then set app.setUserAuthenticated(true);
    //        - and we are done! yay!

    // const cleaner = getEnv(self).cleaner;

    const app = getEnv(self).app;

    self.runInAction(() => {
      // const user = userStore.user;
      app.setUserAuthenticated(true);
    });

    return undefined;
  },
}));

export default Startup;
